unit Casata.WebCore.IssueVotesForm;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls,

  WebCore.Knockout,
  Sigma.WebCore.Types,

  Casata.Properties,
  Casata.Issues.ViewModel;

type
  TIssueVotesForm = class(TForm)
    IssueVotesContainerDiv: THTMLDiv;
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    // -- AG-Grid declarations.
    FIssueVotesGridOptions: TJSObject;
    FIssueVotesGridAPI: TJSObject;
    procedure HandleRowClicked(rowId: integer);

    FIssuesViewModel: IIssuesViewModel;
    FIssueVoteList: TJSArray;
  public
    { Public declarations }
    procedure InitialiseBindings(issuesViewModel: IIssuesViewModel);
    procedure ShowIssueVotes(issueVoteList: TJSArray);
  protected procedure LoadDFMValues; override; end;

var
  IssueVotesForm: TIssueVotesForm;

implementation

{$R *.dfm}

{ TIssueVotesForm }

//------------------------------------------------------------------------------
procedure TIssueVotesForm.HandleRowClicked(rowId: integer);
var
  v: JSValue;
begin
  // -- rowId is the OwnerID associated with the clicked row as retrieved in the
  // -- onSelectionChanged event handler.
  console.log('rowId: ' + IntToStr(rowId));
  FIssuesViewModel.UpdateSelectedVoterId(rowId);
end;
//------------------------------------------------------------------------------
procedure TIssueVotesForm.InitialiseBindings(issuesViewModel: IIssuesViewModel);
var
  e: TJSElement;
begin
  FIssuesViewModel := issuesViewModel;

  e := document.getElementById('active_issue_title_votes');
  e.setAttribute('data-bind', 'text: _activeIssueTitle');

  // -- Rather than configuring the voting controls, just hide the div
  // -- if the user isn't allowed to vote.
  //e := document.getElementById('vote_selection_header');
  //e.setAttribute('data-bind', 'visible: _canVote');

  e := document.getElementById('selected_issue_vote');
  e.setAttribute('data-bind', 'options: _voteOptions, value: _selectedIssueVote, enable: _allowVoteChange, event: {change: EnableVoteUpdate}');

  { TODO : Enable the button only if the user has modified their vote. }
  e := document.getElementById('update_issue_votes');
  e.setAttribute('data-bind', 'click: UpdateOwnerVote, enable: _allowVoteUpdate');

  e := document.getElementById('issue_votes_container_div');
  ko.ApplyBindings(TIssuesViewModel(issuesViewModel), e);
end;
//------------------------------------------------------------------------------
procedure TIssueVotesForm.ShowIssueVotes(issueVoteList: TJSArray);
var
  containerId: string;
begin
  containerId := '#issue_votes_table_div';
  //FIssueVoteList := issueVoteList;

  asm
    this.FIssueVotesGridAPI.setGridOption('rowData', issueVoteList);
  end;
end;
//------------------------------------------------------------------------------
procedure TIssueVotesForm.WebFormCreate(Sender: TObject);
var
  propContainerId: string;
  rowClickProc: TRowClickHandler;
begin
  propContainerId := '#issue_votes_table_div';
  rowClickProc := @HandleRowClicked;

  asm
    this.FIssueVotesGridOptions = {
      rowData: [],
      columnDefs: [
        { field: "OwnerID", headerName: "OwnerID", hide: true },
        { field: "ApartmentNo", headerName: "Apartment No.", suppressMovable: true },
        { field: "OwnerPrimaryContact", headerName: "Owner Name", suppressMovable: true },
        { field: "Vote", headerName: "Vote", suppressMovable: true }
      ],
      rowSelection: 'single',
      onSelectionChanged: (event) => {
        const selectedData = this.FIssueVotesGridAPI.getSelectedRows();
        if(Array.isArray(selectedData) && selectedData.length)
        {
          var id = selectedData[0].OwnerID;
          rowClickProc(id);
        }
      }
    };

    const votesGridElement = document.querySelector(propContainerId);
    this.FIssueVotesGridAPI = agGrid.createGrid(votesGridElement, this.FIssueVotesGridOptions);
  end;
end;

procedure TIssueVotesForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  IssueVotesContainerDiv := THTMLDiv.Create('issue_votes_container_div');

  IssueVotesContainerDiv.BeforeLoadDFMValues;
  try
    Name := 'IssueVotesForm';
    Width := 841;
    Height := 547;
    ElementClassName := 'formheadertext';
    ElementFont := efCSS;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    IssueVotesContainerDiv.SetParentComponent(Self);
    IssueVotesContainerDiv.Name := 'IssueVotesContainerDiv';
    IssueVotesContainerDiv.Left := 36;
    IssueVotesContainerDiv.Top := 16;
    IssueVotesContainerDiv.Width := 763;
    IssueVotesContainerDiv.Height := 515;
    IssueVotesContainerDiv.ElementClassName := 'formcontainer vertical';
    IssueVotesContainerDiv.HeightStyle := ssAuto;
    IssueVotesContainerDiv.WidthStyle := ssAuto;
    IssueVotesContainerDiv.ElementPosition := epIgnore;
    IssueVotesContainerDiv.ElementFont := efCSS;
    IssueVotesContainerDiv.HTML.BeginUpdate;
    try
      IssueVotesContainerDiv.HTML.Clear;
      IssueVotesContainerDiv.HTML.Add('      <div id="issue_votes_header_div" class="formheader">');
      IssueVotesContainerDiv.HTML.Add('        <div id="issue_votes_title_div" class="h-subcontainer left">');
      IssueVotesContainerDiv.HTML.Add('          <span class="formheadertext">Votes for Issue:&nbsp;</span>');
      IssueVotesContainerDiv.HTML.Add('          <span id="active_issue_title_votes" class="formheadertext"></span>');
      IssueVotesContainerDiv.HTML.Add('        </div>');
      IssueVotesContainerDiv.HTML.Add('');
      IssueVotesContainerDiv.HTML.Add('        <div id="issue_vote_selection_container" class="h-subcontainer right">');
      IssueVotesContainerDiv.HTML.Add('          <div id="issue_vote_selector_div" class="x-select">');
      IssueVotesContainerDiv.HTML.Add('            <label for="selected_issue_vote">Select Your Vote:&nbsp;</label>');
      IssueVotesContainerDiv.HTML.Add('            <select id="selected_issue_vote">');
      IssueVotesContainerDiv.HTML.Add('            </select>');
      IssueVotesContainerDiv.HTML.Add('            <span class="focus"></span>');
      IssueVotesContainerDiv.HTML.Add('          </div> ');
      IssueVotesContainerDiv.HTML.Add('');
      IssueVotesContainerDiv.HTML.Add('          <button id="update_issue_votes" class="x-btn primary x-square small">');
      IssueVotesContainerDiv.HTML.Add('            <!-- Tabler Icons 24 stroke 1.4 (check) -->');
      IssueVotesContainerDiv.HTML.Add('            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12l5 5l10 -10" /></g></svg>');
      IssueVotesContainerDiv.HTML.Add('          </button>');
      IssueVotesContainerDiv.HTML.Add('        </div>');
      IssueVotesContainerDiv.HTML.Add('      </div>');
      IssueVotesContainerDiv.HTML.Add('');
      IssueVotesContainerDiv.HTML.Add('      <div id="issue_votes_table_div" class="tablecontainer ag-theme-quartz">');
      IssueVotesContainerDiv.HTML.Add('      </div>');
    finally
      IssueVotesContainerDiv.HTML.EndUpdate;
    end;
    IssueVotesContainerDiv.Role := '';
  finally
    IssueVotesContainerDiv.AfterLoadDFMValues;
  end;
end;

end.