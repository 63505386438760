unit Casata.WebCore.IssueVotesSummaryForm;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls,

  WebCore.Knockout,

  Casata.Issues.ViewModel;

type
  TIssueVotesSummaryForm = class(TForm)
    IssueVotesSummaryContainerDiv: THTMLDiv;
  private
    { Private declarations }
  public
    { Public declarations }
    procedure InitialiseBindings(issuesViewModel: IIssuesViewModel);
  protected procedure LoadDFMValues; override; end;

var
  IssueVotesSummaryForm: TIssueVotesSummaryForm;

implementation

{$R *.dfm}

{ TIssueVotesSummaryForm }

//------------------------------------------------------------------------------
procedure TIssueVotesSummaryForm.InitialiseBindings(
  issuesViewModel: IIssuesViewModel);
var
  e: TJSElement;
begin
  e := document.getElementById('active_issue_title_votes_summary');
  e.setAttribute('data-bind', 'text: _activeIssueTitle');

  e := document.getElementById('selected_issue_vote_summary');
  e.setAttribute('data-bind', 'options: _voteOptions, value: _selectedIssueVote, enable: _canVote, event: {change: EnableVoteUpdate}');

  { TODO : Enable the button only if the user has modified their vote. }
  e := document.getElementById('update_issue_votes_summary');
  e.setAttribute('data-bind', 'click: UpdateOwnerVote, enable: _allowVoteUpdate');


  // -- Bind to FActiveObservableIssue to get the totals.
  e := document.getElementById('issue_votes_summary_div');
  e.setAttribute('data-bind', 'using: FActiveObservableIssue');

  e := document.getElementById('issue_totalvoters_edit');
  e.setAttribute('data-bind', 'value: _numberOfVoters, disable: _readOnly');

  e := document.getElementById('issue_yesvotes_edit');
  e.setAttribute('data-bind', 'value: _yesVotes, disable: _readOnly');

  e := document.getElementById('issue_novotes_edit');
  e.setAttribute('data-bind', 'value: _noVotes, disable: _readOnly');

  e := document.getElementById('issue_undecidedvotes_edit');
  e.setAttribute('data-bind', 'value: _undecidedVotes, disable: _readOnly');

  e := document.getElementById('issue_unassignedvotes_edit');
  e.setAttribute('data-bind', 'value: _unassignedVotes, disable: _readOnly');

  e := document.getElementById('issue_votes_summary_container_div');
  ko.ApplyBindings(TIssuesViewModel(issuesViewModel), e);
end;

procedure TIssueVotesSummaryForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  IssueVotesSummaryContainerDiv := THTMLDiv.Create('issue_votes_summary_container_div');

  IssueVotesSummaryContainerDiv.BeforeLoadDFMValues;
  try
    Name := 'IssueVotesSummaryForm';
    Width := 825;
    Height := 569;
    ElementClassName := 'formheadertext';
    ElementFont := efCSS;
    IssueVotesSummaryContainerDiv.SetParentComponent(Self);
    IssueVotesSummaryContainerDiv.Name := 'IssueVotesSummaryContainerDiv';
    IssueVotesSummaryContainerDiv.Left := 34;
    IssueVotesSummaryContainerDiv.Top := 30;
    IssueVotesSummaryContainerDiv.Width := 739;
    IssueVotesSummaryContainerDiv.Height := 499;
    IssueVotesSummaryContainerDiv.ElementClassName := 'formcontainer vertical';
    IssueVotesSummaryContainerDiv.HeightStyle := ssAuto;
    IssueVotesSummaryContainerDiv.WidthStyle := ssAuto;
    IssueVotesSummaryContainerDiv.ElementPosition := epIgnore;
    IssueVotesSummaryContainerDiv.ElementFont := efCSS;
    IssueVotesSummaryContainerDiv.HTML.BeginUpdate;
    try
      IssueVotesSummaryContainerDiv.HTML.Clear;
      IssueVotesSummaryContainerDiv.HTML.Add('      <div id="issue_votes_summary_header_div" class="formheader">');
      IssueVotesSummaryContainerDiv.HTML.Add('        <div id="issue_votes_summary_title_div" class="h-subcontainer left">');
      IssueVotesSummaryContainerDiv.HTML.Add('          <span class="formheadertext">Votes Summary for Issue:&nbsp;</span>');
      IssueVotesSummaryContainerDiv.HTML.Add('          <span id="active_issue_title_votes_summary" class="formheadertext"></span>');
      IssueVotesSummaryContainerDiv.HTML.Add('        </div>');
      IssueVotesSummaryContainerDiv.HTML.Add('');
      IssueVotesSummaryContainerDiv.HTML.Add('        <div id="issue_vote_summary_selection_container" class="h-subcontainer right">');
      IssueVotesSummaryContainerDiv.HTML.Add('          <div id="issue_vote_summary_selector_div" class="x-select">');
      IssueVotesSummaryContainerDiv.HTML.Add('            <label for="selected_issue_vote_summary">Select Your Vote:&nbsp;</label>');
      IssueVotesSummaryContainerDiv.HTML.Add('            <select id="selected_issue_vote_summary">');
      IssueVotesSummaryContainerDiv.HTML.Add('            </select>');
      IssueVotesSummaryContainerDiv.HTML.Add('            <span class="focus"></span>');
      IssueVotesSummaryContainerDiv.HTML.Add('          </div> ');
      IssueVotesSummaryContainerDiv.HTML.Add('');
      IssueVotesSummaryContainerDiv.HTML.Add('          <button id="update_issue_votes_summary" class="x-btn primary x-square small">');
      IssueVotesSummaryContainerDiv.HTML.Add('            <!-- Tabler Icons 24 stroke 1.4 (check) -->');
      IssueVotesSummaryContainerDiv.HTML.Add('            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="currentColor" stroke-width="1.4" stroke-');
      IssueVotesSummaryContainerDiv.HTML.Add('linecap="round" stroke-linejoin="round"><path d="M5 12l5 5l10 -10" /></g></svg>');
      IssueVotesSummaryContainerDiv.HTML.Add('          </button>');
      IssueVotesSummaryContainerDiv.HTML.Add('        </div>');
      IssueVotesSummaryContainerDiv.HTML.Add('      </div>');
      IssueVotesSummaryContainerDiv.HTML.Add('');
      IssueVotesSummaryContainerDiv.HTML.Add('      <div id="issue_votes_summary_div">');
      IssueVotesSummaryContainerDiv.HTML.Add('          <!-- Row 1 -->');
      IssueVotesSummaryContainerDiv.HTML.Add('          <div id="div_issue_totalvoters" class="floating-label-group">');
      IssueVotesSummaryContainerDiv.HTML.Add('            <input id="issue_totalvoters_edit" type="text" class="input" placeholder=" " required autocomplete="off"/>');
      IssueVotesSummaryContainerDiv.HTML.Add('            <label for="issue_totalvoters_edit" class="floating-label" data-bind="i18n: '#39'totalvoters'#39'">Total Number of Voters</label>              ');
      IssueVotesSummaryContainerDiv.HTML.Add('          </div>');
      IssueVotesSummaryContainerDiv.HTML.Add('');
      IssueVotesSummaryContainerDiv.HTML.Add('          <div id="div_issue_yesvotes" class="floating-label-group">');
      IssueVotesSummaryContainerDiv.HTML.Add('            <input id="issue_yesvotes_edit" type="text" class="input" placeholder=" " required autocomplete="off"/>');
      IssueVotesSummaryContainerDiv.HTML.Add('            <label for="issue_yesvotes_edit" class="floating-label" data-bind="i18n: '#39'yesvotes'#39'">Yes Votes</label>              ');
      IssueVotesSummaryContainerDiv.HTML.Add('          </div>');
      IssueVotesSummaryContainerDiv.HTML.Add('');
      IssueVotesSummaryContainerDiv.HTML.Add('          <div id="div_issue_novotes" class="floating-label-group">');
      IssueVotesSummaryContainerDiv.HTML.Add('            <input id="issue_novotes_edit" type="text" class="input" placeholder=" " required autocomplete="off"/>');
      IssueVotesSummaryContainerDiv.HTML.Add('            <label for="issue_novotes_edit" class="floating-label" data-bind="i18n: '#39'novotes'#39'">No Votes</label>              ');
      IssueVotesSummaryContainerDiv.HTML.Add('          </div>');
      IssueVotesSummaryContainerDiv.HTML.Add('');
      IssueVotesSummaryContainerDiv.HTML.Add('          <div id="div_issue_undecidedvotes" class="floating-label-group">');
      IssueVotesSummaryContainerDiv.HTML.Add('            <input id="issue_undecidedvotes_edit" type="text" class="input" placeholder=" " required autocomplete="off"/>');
      IssueVotesSummaryContainerDiv.HTML.Add('            <label for="issue_undecidedvotes_edit" class="floating-label" data-bind="i18n: '#39'undecidedvotes'#39'">Undecided Votes</label>              ');
      IssueVotesSummaryContainerDiv.HTML.Add('          </div>');
      IssueVotesSummaryContainerDiv.HTML.Add('');
      IssueVotesSummaryContainerDiv.HTML.Add('          <div id="div_issue_unassignedvotes" class="floating-label-group">');
      IssueVotesSummaryContainerDiv.HTML.Add('            <input id="issue_unassignedvotes_edit" type="text" class="input" placeholder=" " required autocomplete="off"/>');
      IssueVotesSummaryContainerDiv.HTML.Add('            <label for="issue_unassignedvotes_edit" class="floating-label" data-bind="i18n: '#39'unassignedvotes'#39'">Unassigned Votes</label>              ');
      IssueVotesSummaryContainerDiv.HTML.Add('          </div>');
      IssueVotesSummaryContainerDiv.HTML.Add('      </div>');
    finally
      IssueVotesSummaryContainerDiv.HTML.EndUpdate;
    end;
    IssueVotesSummaryContainerDiv.Role := '';
  finally
    IssueVotesSummaryContainerDiv.AfterLoadDFMValues;
  end;
end;

end.