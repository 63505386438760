unit Casata.WebCore.JobVotesSummaryForm;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls,

  WebCore.Knockout,

  Casata.Jobs.ViewModel;

type
  TJobVotesSummaryForm = class(TForm)
    JobVotesSummaryContainerDiv: THTMLDiv;
  private
    { Private declarations }
  public
    { Public declarations }
    procedure InitialiseBindings(jobsViewModel: IJobsViewModel);
  protected procedure LoadDFMValues; override; end;

var
  JobVotesSummaryForm: TJobVotesSummaryForm;

implementation

{$R *.dfm}

{ TJobVotesSummaryForm }

//------------------------------------------------------------------------------
procedure TJobVotesSummaryForm.InitialiseBindings(
  jobsViewModel: IJobsViewModel);
begin

end;

procedure TJobVotesSummaryForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  JobVotesSummaryContainerDiv := THTMLDiv.Create('job_votes_summary_container_div');

  JobVotesSummaryContainerDiv.BeforeLoadDFMValues;
  try
    Name := 'JobVotesSummaryForm';
    Width := 921;
    Height := 588;
    ElementClassName := 'formheadertext';
    ElementFont := efCSS;
    JobVotesSummaryContainerDiv.SetParentComponent(Self);
    JobVotesSummaryContainerDiv.Name := 'JobVotesSummaryContainerDiv';
    JobVotesSummaryContainerDiv.Left := 34;
    JobVotesSummaryContainerDiv.Top := 30;
    JobVotesSummaryContainerDiv.Width := 739;
    JobVotesSummaryContainerDiv.Height := 499;
    JobVotesSummaryContainerDiv.ElementClassName := 'formcontainer vertical';
    JobVotesSummaryContainerDiv.HeightStyle := ssAuto;
    JobVotesSummaryContainerDiv.WidthStyle := ssAuto;
    JobVotesSummaryContainerDiv.ElementPosition := epIgnore;
    JobVotesSummaryContainerDiv.ElementFont := efCSS;
    JobVotesSummaryContainerDiv.HTML.BeginUpdate;
    try
      JobVotesSummaryContainerDiv.HTML.Clear;
      JobVotesSummaryContainerDiv.HTML.Add('      <div id="job_votes_summary_header_div" class="formheader">');
      JobVotesSummaryContainerDiv.HTML.Add('        <div id="job_votes_summary_title_div" class="h-subcontainer left">');
      JobVotesSummaryContainerDiv.HTML.Add('          <span class="formheadertext">Votes Summary for Job:&nbsp;</span>');
      JobVotesSummaryContainerDiv.HTML.Add('          <span id="active_job_title_votes_summary" class="formheadertext"></span>');
      JobVotesSummaryContainerDiv.HTML.Add('        </div>');
      JobVotesSummaryContainerDiv.HTML.Add('');
      JobVotesSummaryContainerDiv.HTML.Add('        <div id="job_vote_summary_selection_container" class="h-subcontainer right">');
      JobVotesSummaryContainerDiv.HTML.Add('          <div id="job_vote_summary_selector_div" class="x-select">');
      JobVotesSummaryContainerDiv.HTML.Add('            <label for="selected_job_vote_summary">Select Your Vote:&nbsp;</label>');
      JobVotesSummaryContainerDiv.HTML.Add('            <select id="selected_job_vote_summary">');
      JobVotesSummaryContainerDiv.HTML.Add('');
      JobVotesSummaryContainerDiv.HTML.Add('            </select>');
      JobVotesSummaryContainerDiv.HTML.Add('            <span class="focus"></span>');
      JobVotesSummaryContainerDiv.HTML.Add('          </div>  ');
      JobVotesSummaryContainerDiv.HTML.Add('');
      JobVotesSummaryContainerDiv.HTML.Add('          <button id="update_job_votes_summary" class="x-btn primary x-square small">');
      JobVotesSummaryContainerDiv.HTML.Add('            <!-- Tabler Icons 24 stroke 1.4 (check) -->');
      JobVotesSummaryContainerDiv.HTML.Add('            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="currentColor" stroke-width="1.4" stroke-');
      JobVotesSummaryContainerDiv.HTML.Add('linecap="round" stroke-linejoin="round"><path d="M5 12l5 5l10 -10" /></g></svg>');
      JobVotesSummaryContainerDiv.HTML.Add('          </button>');
      JobVotesSummaryContainerDiv.HTML.Add('        </div>');
      JobVotesSummaryContainerDiv.HTML.Add('      </div>');
      JobVotesSummaryContainerDiv.HTML.Add('');
      JobVotesSummaryContainerDiv.HTML.Add('      <div id="job_votes_summary_div">');
      JobVotesSummaryContainerDiv.HTML.Add('      </div>');
    finally
      JobVotesSummaryContainerDiv.HTML.EndUpdate;
    end;
    JobVotesSummaryContainerDiv.Role := '';
  finally
    JobVotesSummaryContainerDiv.AfterLoadDFMValues;
  end;
end;

end.