program casata;

uses
  Vcl.Forms,
  WEBLib.Forms,
  UnitMain in 'UnitMain.pas'{*.html},
  Casata.MainForm.ViewModel in 'H:\Casata_Classes\Casata.MainForm.ViewModel.pas',
  Sigma.WebCore.BaseAppViewModel in 'H:\WebCore_Classes\Sigma.WebCore.BaseAppViewModel.pas',
  Sigma.BaseWebFormManager in 'H:\WebCore_Classes\Sigma.BaseWebFormManager.pas',
  Casata.WebCore.UIMediator in 'H:\Casata_Classes\Casata.WebCore.UIMediator.pas',
  Sigma.WebCore.LoginForm in 'H:\WebCore_Classes\Sigma.WebCore.LoginForm.pas',
  Casata.WebCore.WelcomeForm in 'H:\Casata_Classes\Casata.WebCore.WelcomeForm.pas',
  Sigma.WebCore.UsersForm in 'H:\WebCore_Classes\Sigma.WebCore.UsersForm.pas',
  Sigma.Users.ViewModel in 'H:\Web_Classes\Sigma.Users.ViewModel.pas',
  Sigma.BaseViewModel in 'H:\Web_Classes\Sigma.BaseViewModel.pas',
  Casata.WebCore.ProvidersForm in 'H:\Casata_Classes\Casata.WebCore.ProvidersForm.pas',
  Casata.WebCore.JobsForm in 'H:\Casata_Classes\Casata.WebCore.JobsForm.pas',
  Casata.Job in 'H:\Casata_Classes\Casata.Job.pas',
  Casata.ObservableJob in 'H:\Casata_Classes\Casata.ObservableJob.pas',
  Casata.mORMotWeb.JobsClient in 'H:\Casata_Classes\Casata.mORMotWeb.JobsClient.pas',
  Sigma.BaseFormViewModel in 'H:\Web_Classes\Sigma.BaseFormViewModel.pas',
  Casata.Constants in 'H:\Casata_Classes\Casata.Constants.pas',
  Casata.ContextMenuManager in 'H:\Casata_Classes\Casata.ContextMenuManager.pas',
  Casata.WebCore.JobsContextMenu in 'H:\Casata_Classes\Casata.WebCore.JobsContextMenu.pas',
  Casata.JobContextMenu.ViewModel in 'H:\Casata_Classes\Casata.JobContextMenu.ViewModel.pas',
  Casata.WebCore.ProvidersMenu in 'H:\Casata_Classes\Casata.WebCore.ProvidersMenu.pas',
  Casata.Types in 'H:\Casata_Classes\Casata.Types.pas',
  Casata.WebCore.JobDiscussionsForm in 'H:\Casata_Classes\Casata.WebCore.JobDiscussionsForm.pas',
  Casata.JobVotes.ViewModel in 'H:\Casata_Classes\Casata.JobVotes.ViewModel.pas',
  Sigma.WebCore.Constants in 'H:\WebCore_Classes\Sigma.WebCore.Constants.pas',
  Sigma.mORMotWeb.DiscussionClient in 'H:\mORMot_Classes\Sigma.mORMotWeb.DiscussionClient.pas',
  Sigma.WebCore.BaseDiscussionPostsForm in 'H:\WebCore_Classes\Sigma.WebCore.BaseDiscussionPostsForm.pas',
  Sigma.ObservableDiscussion in 'H:\WebCore_Classes\Sigma.ObservableDiscussion.pas',
  Casata.WebCore.JobDiscussionPostsForm in 'H:\Casata_Classes\Casata.WebCore.JobDiscussionPostsForm.pas',
  Casata.WebCore.JobVotesForm in 'H:\Casata_Classes\Casata.WebCore.JobVotesForm.pas',
  Casata.WebCore.PropertiesForm in 'H:\Casata_Classes\Casata.WebCore.PropertiesForm.pas',
  Casata.Properties.ViewModel in 'H:\Casata_Classes\Casata.Properties.ViewModel.pas',
  Casata.mORMotWeb.PropertiesClient in 'H:\Casata_Classes\Casata.mORMotWeb.PropertiesClient.pas',
  Casata.ObservableProperty in 'H:\Casata_Classes\Casata.ObservableProperty.pas',
  Sigma.BaseMasterDetailFormViewModel in 'H:\Web_Classes\Sigma.BaseMasterDetailFormViewModel.pas',
  Casata.JobWizardManager in 'H:\Casata_Classes\Casata.JobWizardManager.pas',
  Sigma.WizardContainerForm in 'H:\WebCore_Classes\Sigma.WizardContainerForm.pas',
  Casata.IssueWizardPage1 in 'H:\Casata_Classes\Casata.IssueWizardPage1.pas',
  Casata.IssueWizardPage2 in 'H:\Casata_Classes\Casata.IssueWizardPage2.pas',
  Casata.JobWizardPage3 in 'H:\Casata_Classes\Casata.JobWizardPage3.pas',
  Sigma.BaseWizardManager in 'H:\WebCore_Classes\Sigma.BaseWizardManager.pas',
  Casata.PropertyGroupStructure in 'H:\Casata_Classes\Casata.PropertyGroupStructure.pas',
  Casata.WebCore.AdminForm in 'H:\Casata_Classes\Casata.WebCore.AdminForm.pas',
  Casata.Admin.ViewModel in 'H:\Casata_Classes\Casata.Admin.ViewModel.pas',
  Casata.mORMotWeb.AdminClient in 'H:\Casata_Classes\Casata.mORMotWeb.AdminClient.pas',
  Casata.JobWizardPage4 in 'H:\Casata_Classes\Casata.JobWizardPage4.pas',
  Casata.WebCore.IssuesForm in 'H:\Casata_Classes\Casata.WebCore.IssuesForm.pas',
  Casata.mORMotWeb.IssuesClient in 'H:\Casata_Classes\Casata.mORMotWeb.IssuesClient.pas',
  Casata.Issues.ViewModel in 'H:\Casata_Classes\Casata.Issues.ViewModel.pas',
  Casata.Issue in 'H:\Casata_Classes\Casata.Issue.pas',
  Casata.IssuesContextMenu.ViewModel in 'H:\Casata_Classes\Casata.IssuesContextMenu.ViewModel.pas',
  Casata.ObservableIssue in 'H:\Casata_Classes\Casata.ObservableIssue.pas',
  Casata.WebCore.IssuesContextMenu in 'H:\Casata_Classes\Casata.WebCore.IssuesContextMenu.pas',
  Casata.JobWizardPage1 in 'H:\Casata_Classes\Casata.JobWizardPage1.pas',
  Casata.JobWizardPage2 in 'H:\Casata_Classes\Casata.JobWizardPage2.pas',
  Casata.IssueWizardManager in 'H:\Casata_Classes\Casata.IssueWizardManager.pas',
  Casata.IssueWizardContainerForm in 'H:\Casata_Classes\Casata.IssueWizardContainerForm.pas',
  Sigma.Discussions.ViewModel in 'H:\WebCore_Classes\Sigma.Discussions.ViewModel.pas',
  Casata.WebCore.IssueDiscussionsForm in 'H:\Casata_Classes\Casata.WebCore.IssueDiscussionsForm.pas',
  Casata.WebCore.IssueDiscussionPostsForm in 'H:\Casata_Classes\Casata.WebCore.IssueDiscussionPostsForm.pas',
  Casata.WebCore.AnnouncementsForm in 'H:\Casata_Classes\Casata.WebCore.AnnouncementsForm.pas',
  Casata.Announcements.ViewModel in 'H:\Casata_Classes\Casata.Announcements.ViewModel.pas',
  Casata.mORMotWeb.AnnouncementsClient in 'H:\Casata_Classes\Casata.mORMotWeb.AnnouncementsClient.pas',
  Sigma.ObservableUser in 'H:\Web_Classes\Sigma.ObservableUser.pas',
  Sigma.WebCore.BaseUIMediator in 'H:\WebCore_Classes\Sigma.WebCore.BaseUIMediator.pas',
  Sigma.Login.ViewModel in 'H:\Web_Classes\Sigma.Login.ViewModel.pas',
  Sigma.UserAccount.ViewModel in 'H:\Web_Classes\Sigma.UserAccount.ViewModel.pas',
  Sigma.WebCore.UserAccountForm in 'H:\WebCore_Classes\Sigma.WebCore.UserAccountForm.pas',
  Casata.WebCore.QuotationsForm in 'H:\Casata_Classes\Casata.WebCore.QuotationsForm.pas',
  Casata.mORMotWeb.QuotationsClient in 'H:\Casata_Classes\Casata.mORMotWeb.QuotationsClient.pas',
  Casata.Quotations.ViewModel in 'H:\Casata_Classes\Casata.Quotations.ViewModel.pas',
  Casata.ObservableQuotation in 'H:\Casata_Classes\Casata.ObservableQuotation.pas',
  Sigma.Web.Utils in 'H:\Web_Classes\Sigma.Web.Utils.pas',
  Casata.JobWizardContainerForm in 'H:\Casata_Classes\Casata.JobWizardContainerForm.pas',
  Sigma.Web.DateUtils in 'H:\Web_Classes\Sigma.Web.DateUtils.pas',
  Casata.WebCore.IssueVotesForm in 'H:\Casata_Classes\Casata.WebCore.IssueVotesForm.pas',
  Sigma.WebControls in 'H:\WebCore_Classes\Sigma.WebControls.pas',
  Casata.IssueWizardPage3 in 'H:\Casata_Classes\Casata.IssueWizardPage3.pas',
  Sigma.WebCore.TabsViewModel in 'H:\WebCore_Classes\Sigma.WebCore.TabsViewModel.pas',
  Sigma.BaseTabbedViewModel in 'H:\Web_Classes\Sigma.BaseTabbedViewModel.pas',
  Sigma.mORMotWeb.LanguagesClient in 'H:\mORMot_Classes\Sigma.mORMotWeb.LanguagesClient.pas',
  Sigma.Languages.ViewModel in 'H:\Web_Classes\Sigma.Languages.ViewModel.pas',
  Casata.Events in 'H:\Casata_Classes\Casata.Events.pas',
  Casata.WebCore.HomeForm in 'H:\Casata_Classes\Casata.WebCore.HomeForm.pas',
  Casata.HomePage.ViewModel in 'H:\Casata_Classes\Casata.HomePage.ViewModel.pas',
  Casata.mORMotWeb.FilesClient in 'H:\Casata_Classes\Casata.mORMotWeb.FilesClient.pas',
  Casata.Files.ViewModel in 'H:\Casata_Classes\Casata.Files.ViewModel.pas',
  Casata.HomeContextMenu.ViewModel in 'H:\Casata_Classes\Casata.HomeContextMenu.ViewModel.pas',
  Casata.WebCore.DocumentsForm in 'H:\Casata_Classes\Casata.WebCore.DocumentsForm.pas',
  Casata.WebCore.HomeContextMenu in 'H:\Casata_Classes\Casata.WebCore.HomeContextMenu.pas',
  Casata.ObservableDocument in 'H:\Casata_Classes\Casata.ObservableDocument.pas',
  Sigma.WebCore.BaseAppViewModel_Intf in 'H:\WebCore_Classes\Sigma.WebCore.BaseAppViewModel_Intf.pas',
  Casata.mORMotWeb.AuthClient in 'H:\Casata_Classes\Casata.mORMotWeb.AuthClient.pas',
  Casata.ObservableUser in 'H:\Casata_Classes\Casata.ObservableUser.pas',
  Sigma.BaseWebForm in 'H:\WebCore_Classes\Sigma.BaseWebForm.pas',
  Casata.WebCore.UsersForm in 'H:\Casata_Classes\Casata.WebCore.UsersForm.pas',
  Casata.User in 'H:\Casata_Classes\Casata.User.pas',
  Casata.UserObj in 'H:\Casata_Classes\Casata.UserObj.pas',
  Casata.WebCore.IssueVotesSummaryForm in 'H:\Casata_Classes\Casata.WebCore.IssueVotesSummaryForm.pas',
  Casata.WebCore.JobVotesSummaryForm in 'H:\Casata_Classes\Casata.WebCore.JobVotesSummaryForm.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TMainForm, MainForm);
  Application.Run;
end.
