unit Casata.WebCore.UsersForm;

interface

uses
  System.SysUtils,
  System.Classes,

  Vcl.Controls,

  JS,
  Web,

  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.WebCtrls,

  Sigma.WebCore.UsersForm,

  Casata.Users.ViewModel;

type
  TCasataUsersForm = class(TUsersForm)
  private
    { Private declarations }
  public
    { Public declarations }
    //procedure ApplyBindings(usersViewModel: ICasataUsersViewModel); override;
    procedure InitialiseBindings(usersViewModel: ICasataUsersViewModel); reintroduce;
  protected procedure LoadDFMValues; override; end;

var
  CasataUsersForm: TCasataUsersForm;

implementation

{$R *.dfm}

{ TCasataUsersForm }

//------------------------------------------------------------------------------
//procedure TCasataUsersForm.ApplyBindings(usersViewModel: ICasataUsersViewModel);
//begin
//  inherited ApplyBindings(usersViewModel);

//end;
//------------------------------------------------------------------------------
procedure TCasataUsersForm.InitialiseBindings(
  usersViewModel: ICasataUsersViewModel);
var
  e: TJSElement;
begin
  inherited InitialiseBindings(usersViewModel);

  // -- Re-bind the refresh button.
  // -- Used for testing - override GetUsers rather than have a separate method.
  //e := document.getElementById('navbtn_refresh_users');
  //e.setAttribute('data-bind', 'click: $root.GetCasataUsers, disable: $root._addEntityMode');

  // -- Bind the delete user dialog elements.
  e := document.getElementById('btn_confirm_user_delete');
  e.setAttribute('data-bind', 'click: ConfirmDeleteUser');

  e := document.getElementById('btn_cancel_user_delete');
  e.setAttribute('data-bind', 'click: CancelDeleteUser');

  // -- Bind the dialog header.
  e := document.getElementById('dialog_user_name');
  e.setAttribute('data-bind', 'text: _activeUserName');


  // -- Bind the location filter.
  e := document.getElementById('users_location_filter_select');
  e.setAttribute('data-bind', 'options: $root._blocks, optionsText: "BlockName", value: $root._selectedBlock');

  e := document.getElementById('casata_user_details_grid');
  e.setAttribute('data-bind', 'using: _activeObservableCasataUser');

  e := document.getElementById('has_voting_rights');
  e.setAttribute('data-bind', 'checked: _hasVotingRights, disable: $root._defaultNavigationMode');

  e := document.getElementById('has_multiple_properties');
  e.setAttribute('data-bind', 'checked: _hasMultipleProperties, disable: $root._defaultNavigationMode');

  e := document.getElementById('user_location_select');
  e.setAttribute('data-bind', 'options: $root._userLocations, value: _userLocation, disable: $root._defaultNavigationMode');

  e := document.getElementById('user_notes_div');
  e.setAttribute('data-bind', 'using: _activeObservableCasataUser');

  // -- User notes binding.
  e := document.getElementById('user_notes_text');
  e.setAttribute('data-bind', 'value: _userNotes, disable: $root._defaultNavigationMode');
end;

procedure TCasataUsersForm.LoadDFMValues;
begin
  inherited LoadDFMValues;


  UsersContainerDiv.BeforeLoadDFMValues;
  try
    Caption := 'CasataUsersForm';
    UsersContainerDiv.Width := 735;
    UsersContainerDiv.Height := 451;
    UsersContainerDiv.HTML.BeginUpdate;
    try
      UsersContainerDiv.HTML.Clear;
      UsersContainerDiv.HTML.Add('      <div id="users_header_div" class="formheader rounded">');
      UsersContainerDiv.HTML.Add('        <div id="users_title_div" class="h-subcontainer left">');
      UsersContainerDiv.HTML.Add('          <span class="formheadertext" data-bind="i18n: '#39'users'#39'">Users</span>');
      UsersContainerDiv.HTML.Add('        </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('        <div id="users_controls_div" class="h-subcontainer right">');
      UsersContainerDiv.HTML.Add('          <button id="navbtn_refresh_users" class="x-btn primary x-square small">');
      UsersContainerDiv.HTML.Add('            <!-- Tabler Icons 24 stroke 1.4 (refresh) -->');
      UsersContainerDiv.HTML.Add('            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">');
      UsersContainerDiv.HTML.Add('              <g fill="none" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">');
      UsersContainerDiv.HTML.Add('              <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />');
      UsersContainerDiv.HTML.Add('              <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" /></g>');
      UsersContainerDiv.HTML.Add('            </svg>');
      UsersContainerDiv.HTML.Add('          </button>');
      UsersContainerDiv.HTML.Add('          <button id="navbtn_insert_user" class="x-btn primary x-square small">');
      UsersContainerDiv.HTML.Add('            <!-- Tabler Icons 24 stroke 1.4 (plus) -->');
      UsersContainerDiv.HTML.Add('            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"><path d="M12 5l0 14" />');
      UsersContainerDiv.HTML.Add('              <path d="M5 12l14 0" /></g></svg>');
      UsersContainerDiv.HTML.Add('          </button>');
      UsersContainerDiv.HTML.Add('          <button id="navbtn_delete_user" class="x-btn primary x-square small">');
      UsersContainerDiv.HTML.Add('            <!-- Tabler Icons 24 stroke 1.4 (minus) -->');
      UsersContainerDiv.HTML.Add('            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">');
      UsersContainerDiv.HTML.Add('              <g fill="none" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round">');
      UsersContainerDiv.HTML.Add('                <path d="M5 12l14 0" />');
      UsersContainerDiv.HTML.Add('              </g>');
      UsersContainerDiv.HTML.Add('            </svg>');
      UsersContainerDiv.HTML.Add('          </button>');
      UsersContainerDiv.HTML.Add('          <button id="navbtn_edit_user" class="x-btn primary x-square small">');
      UsersContainerDiv.HTML.Add('            <!-- Tabler Icons 24 stroke 1.4 (edit) -->');
      UsersContainerDiv.HTML.Add('            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />');
      UsersContainerDiv.HTML.Add('              <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />');
      UsersContainerDiv.HTML.Add('              <path d="M16 5l3 3" /></g></svg>');
      UsersContainerDiv.HTML.Add('          </button>');
      UsersContainerDiv.HTML.Add('          <button id="navbtn_save_user" class="x-btn primary x-square small">');
      UsersContainerDiv.HTML.Add('            <!-- Tabler Icons 24 stroke 1.4 (check) -->');
      UsersContainerDiv.HTML.Add('            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12l5 5l10 -10" /></g></svg>');
      UsersContainerDiv.HTML.Add('          </button>');
      UsersContainerDiv.HTML.Add('          <button id="navbtn_cancel_change_user" class="x-btn primary x-square small">');
      UsersContainerDiv.HTML.Add('            <!-- Tabler Icons 24 stroke 1.4 (arrow-back-up) -->');
      UsersContainerDiv.HTML.Add('            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"><path d="M9 14l-4 -4l4 -4" />');
      UsersContainerDiv.HTML.Add('              <path d="M5 10h11a4 4 0 1 1 0 8h-1" /></g></svg>');
      UsersContainerDiv.HTML.Add('          </button>');
      UsersContainerDiv.HTML.Add('        </div>');
      UsersContainerDiv.HTML.Add('      </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('      <div id="users_master_div" class="datamaster">');
      UsersContainerDiv.HTML.Add('        <div id="users_table_div" class="tablecontainer ag-theme-quartz">');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('        </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('        <div id="users_nav_container_div" class="h-subcontainer spaced medium">');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('          <div id="users_location_selector_div" class="x-select">');
      UsersContainerDiv.HTML.Add('            <label for="users_location_filter_select" data-bind="i18n: '#39'location'#39'">Location</label>');
      UsersContainerDiv.HTML.Add('            <select id="users_location_filter_select">');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('            </select>');
      UsersContainerDiv.HTML.Add('            <span class="focus"></span>');
      UsersContainerDiv.HTML.Add('          </div>');
      UsersContainerDiv.HTML.Add('        </div>');
      UsersContainerDiv.HTML.Add('      </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('      <!-- Lay out the controls for the data details section. -->');
      UsersContainerDiv.HTML.Add('      <div class="datadetail-container">                ');
      UsersContainerDiv.HTML.Add('        <div id="user_tabs_div" class="datadetailtabs">');
      UsersContainerDiv.HTML.Add('          <ul class="x-tab" role="tablist">');
      UsersContainerDiv.HTML.Add('            <li role="tab"> ');
      UsersContainerDiv.HTML.Add('              <a id="userdetails_tab" href="#userdetails">Details</a>');
      UsersContainerDiv.HTML.Add('            </li>');
      UsersContainerDiv.HTML.Add('            <li role="tab">');
      UsersContainerDiv.HTML.Add('              <a id="usernotes_tab" href="#usernotes">Notes</a>');
      UsersContainerDiv.HTML.Add('            </li>');
      UsersContainerDiv.HTML.Add('          </ul>          ');
      UsersContainerDiv.HTML.Add('        </div>  ');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('        <div id="user_detail_div" class="datadetail-container">');
      UsersContainerDiv.HTML.Add('          <div id="userdetails_tabpanel" class="x-tabpanel" role="tabpanel">');
      UsersContainerDiv.HTML.Add('            <div id="user_details_grid" class="control-container-grid r3-c2">');
      UsersContainerDiv.HTML.Add('              <div id="div_firstname" class="floating-label-group">');
      UsersContainerDiv.HTML.Add('                <input id="first_name_edit" type="text" class="input" placeholder=" " required autocomplete="off"/>');
      UsersContainerDiv.HTML.Add('                <label for="first_name_edit" class="floating-label">First Name</label>              ');
      UsersContainerDiv.HTML.Add('              </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('              <div id="div_surname" class="floating-label-group">');
      UsersContainerDiv.HTML.Add('                <input id="surname_edit" type="text" class="input" placeholder=" " required autocomplete="off"/>');
      UsersContainerDiv.HTML.Add('                <label for="surname_edit" class="floating-label">Surname</label>              ');
      UsersContainerDiv.HTML.Add('              </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('              <div id="div_logonname" class="floating-label-group">');
      UsersContainerDiv.HTML.Add('                <input id="logon_name_edit" type="text" class="input" placeholder=" " required autocomplete="off"/>');
      UsersContainerDiv.HTML.Add('                <label id="label_logon_name_edit" for="logon_name_edit" class="floating-label">Logon Name</label>              ');
      UsersContainerDiv.HTML.Add('              </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('              <div id="user_type_selector_div" class="x-select">');
      UsersContainerDiv.HTML.Add('                <label for="user_type_select">User Type</label>');
      UsersContainerDiv.HTML.Add('                <select id="user_type_select">');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('                </select>');
      UsersContainerDiv.HTML.Add('                <span class="focus"></span>');
      UsersContainerDiv.HTML.Add('              </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('              <div id="div_is_sysadmin" class="">');
      UsersContainerDiv.HTML.Add('                <label class="checkbox">');
      UsersContainerDiv.HTML.Add('                  <input type="checkbox" id="is_sys_admin">');
      UsersContainerDiv.HTML.Add('                  <span data-bind="i18n: '#39'issysadmin'#39'">System Admin</span>');
      UsersContainerDiv.HTML.Add('                </label>');
      UsersContainerDiv.HTML.Add('              </div>');
      UsersContainerDiv.HTML.Add('            </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('            <!-- Create a separate grid for the Casata-specific fields. -->');
      UsersContainerDiv.HTML.Add('            <!-- Set the binding context in the form to use the appropriate class. -->');
      UsersContainerDiv.HTML.Add('            <div id="casata_user_details_grid" class="control-container-grid r2-c2">                            ');
      UsersContainerDiv.HTML.Add('              <div id="div_hasvotingrights" class="">');
      UsersContainerDiv.HTML.Add('                <label class="checkbox">');
      UsersContainerDiv.HTML.Add('                  <input type="checkbox" id="has_voting_rights">');
      UsersContainerDiv.HTML.Add('                  <span data-bind="i18n: '#39'hasvotingrights'#39'">Voting Rights</span>');
      UsersContainerDiv.HTML.Add('                </label>');
      UsersContainerDiv.HTML.Add('              </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('              <div id="div_hasmultipleproperties" class="">');
      UsersContainerDiv.HTML.Add('                <label class="checkbox">');
      UsersContainerDiv.HTML.Add('                  <input type="checkbox" id="has_multiple_properties">');
      UsersContainerDiv.HTML.Add('                  <span data-bind="i18n: '#39'hasmultipleproperties'#39'">Multiple Properties</span>');
      UsersContainerDiv.HTML.Add('                </label>');
      UsersContainerDiv.HTML.Add('              </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('              <div id="user_location_selector_div" class="x-select">');
      UsersContainerDiv.HTML.Add('                <label for="user_location_select" data-bind="i18n: '#39'location'#39'">Location</label>');
      UsersContainerDiv.HTML.Add('                <select id="user_location_select" class="translate">');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('                </select>');
      UsersContainerDiv.HTML.Add('                <span class="focus"></span>');
      UsersContainerDiv.HTML.Add('              </div>');
      UsersContainerDiv.HTML.Add('            </div>');
      UsersContainerDiv.HTML.Add('          </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('          <div id="usernotes_tabpanel" class="x-tabpanel" role="tabpanel">');
      UsersContainerDiv.HTML.Add('            <div id="user_notes_div" class="">');
      UsersContainerDiv.HTML.Add('              <div class="textarea-container">');
      UsersContainerDiv.HTML.Add('                <textarea id="user_notes_text" rows="12" placeholder=" "></textarea>');
      UsersContainerDiv.HTML.Add('                <label id="label_user_notes" for="user_notes_text" class="placeholder">Notes</label>');
      UsersContainerDiv.HTML.Add('              </div>');
      UsersContainerDiv.HTML.Add('            </div>');
      UsersContainerDiv.HTML.Add('          </div>');
      UsersContainerDiv.HTML.Add('        </div>');
      UsersContainerDiv.HTML.Add('      </div>        <!-- wrapper datadetail-container -->');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('      <!-- Define the dialog to confirm user deletion. -->');
      UsersContainerDiv.HTML.Add('      <!-- TODO: Red alert icon. -->');
      UsersContainerDiv.HTML.Add('      <dialog id="delete_user_dialog" class="modal centre">');
      UsersContainerDiv.HTML.Add('        <div class="dialog-header">');
      UsersContainerDiv.HTML.Add('          <span class="dialog-title" data-bind="i18n: '#39'deleteselecteduser'#39'">Delete Selected User:&nbsp;</span>   ');
      UsersContainerDiv.HTML.Add('          <span id="dialog_user_name" class="dialog-title"></span>         ');
      UsersContainerDiv.HTML.Add('        </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('        <div id="delete_user_dialog_div" class="dialog-container small fixed-width-large" data-bind="i18n: '#39'confirmuserdeletion'#39'">');
      UsersContainerDiv.HTML.Add('          Delete the selected user: Are you sure?              ');
      UsersContainerDiv.HTML.Add('        </div>');
      UsersContainerDiv.HTML.Add('');
      UsersContainerDiv.HTML.Add('        <div id="delete_user_dialog_footer" class="dialog-footer">');
      UsersContainerDiv.HTML.Add('          <button id="btn_confirm_user_delete"  class="x-btn dialog primary" data-bind="i18n: '#39'confirm'#39'">Ok</button>');
      UsersContainerDiv.HTML.Add('          <button id="btn_cancel_user_delete"  class="x-btn dialog primary" data-bind="i18n: '#39'cancel'#39'">Cancel</button>');
      UsersContainerDiv.HTML.Add('        </div>');
      UsersContainerDiv.HTML.Add('      </dialog>');
    finally
      UsersContainerDiv.HTML.EndUpdate;
    end;
  finally
    UsersContainerDiv.AfterLoadDFMValues;
  end;
end;

end.
